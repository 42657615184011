
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code2 } from "./data";

export default defineComponent({
  name: "with-allow-half",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      value: ref(null),
      code2
    };
  }
});
